<template>
    <div>

        <div class="main">
            <div class="title">
                会员中心
            </div>

            <div class="vip_body">
                <div class="vip_chose" v-for="(item,index) in detail" :key='index' :style="item.grade==3?'background:#373D55':''">
                    <div class="vip-type">
                        <img src="../assets/vip.png" alt="" srcset="">
                        {{item.name}}
                    </div>
                    <div class="vip-desc">
                            <div class="vip-detail">
                                <div class="circle-style"></div>
                                {{item.msg}}
                            </div>
                            <!-- <div class="vip-detail">
                                <div class="circle-style"></div>
                                可使用免费标书审查服务
                            </div> -->
                        <div class="vip-foot">
                            <div class="vip-money">
                                ￥<span style="font-size:43px;"><b>{{item.money}}</b></span> 元/月
                            </div>
                            <div class="buy-vip" @click="jump(item)">
                                立即开通
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="vip_chose" style="background:#373D55;">
                    <div class="vip-type">
                        <img src="../assets/vip.png" alt="" srcset="">
                        月费会员
                    </div>
                    <div class="vip-desc">
                            <div class="vip-detail">
                                <div class="circle-style"></div>
                                可使用免费标书审查服务
                            </div>
                            <div class="vip-detail">
                                <div class="circle-style"></div>
                                可使用免费标书审查服务
                            </div>
                        <div class="vip-foot">
                            <div class="vip-money">
                                ￥<span style="font-size:43px;"><b>300</b></span> 元/月
                            </div>
                            <div class="buy-vip" >
                                立即开通
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
            

            <div class="history">
                <div class="smallTitle text_left">
                    历史记录
                </div>

                <div class="order" v-for="(item,index) in order_list" :key='index'>
                    <div class="order-desc">
                        <img src="../assets/order.png" alt="" srcset="">
                        <b>订单号：</b>{{item.source_id}} 
                        <span style="margin-left:20px;"><b>创建时间</b>：{{item.ctime}}</span> 
                    </div>
                    <div class="order-number">
                        <b>订单说明</b>：{{item.memo}}
                    </div>
                    <div class="order-status">
                        <div>
                            订单状态：已支付
                        </div>
                        <!-- <div class="date">
                            2021-05-26到期
                        </div> -->
                    </div>
                </div>
            </div>

    </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            detail:[],
            order_list:'',
			searchData:{
				type:9,
				limit:10,
				page:2
			}
        }
    },
    created(){
        this.get_vip()
        this.loadData()
    },
    methods:{
             get_vip(){
                    this.$api.post('payments.getvipinfo',{},(res)=>{
                        if(res.data.status){
                            for(let i in res.data.data){
                                this.detail.push(res.data.data[i])
                            }
                            console.log(this.detail)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            loadData(){
				this.$api.userpost('user.balancelist',this.searchData,(res)=>{
					if(res.data.status){
						this.order_list = res.data.data
					}else{
						this.$message.error(res.data.msg)
					}
				})
            },
            jump(item){
                let data = {
                    name:item.name,
                    money:item.money,
                    grade:item.grade,
                    type:3//写死的
                }
                // this.$router.push({path:'/pay',query:{order_detail:JSON.stringify(data)}})
                this.$router.push({path:'/pay',query:{order_detail:JSON.stringify(data)}})

            }
        }
}
</script>
<style lang="scss" scoped>
     .main{
        padding: 30px;
        margin: 40px auto;
        width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .title{
       text-align: left;
       font-size: 26px; 
       color: #144591;
    }

    .vip_body{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .vip_chose{
		border: 0.5px #373D55 solid;
		width: 555px;
		height: 240px;
		background: #56628F;
		border-radius: 10px;
	}
	
	.vip-type{
		margin: 29px 0 0 20px;
		font-size: 32px;
		color: #DEAA8A;
		display: flex;
		align-items: center;
		img{
			height: 26px;
			width: 31px;
			margin-right: 15px;
		}
	}
	
	.vip-desc{
		margin-top: 22px;
	}
	
	.vip-detail{
        margin-top: 10px;
		margin-left: 82px;
		display: flex;
		align-items: center;
		color: #DEAA8A;
		font-size: 25upx;
	}
	
	.circle-style{
		height: 7px;
		width: 7px;
		border-radius: 50%;
		background-color: #E7B394;
		margin-right: 13px;
	}
	
	.vip-foot{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: 32px;
	}
	
	.vip-money{
		color: #EDAA8A;
		margin-left: 20px;
	}
	
	.buy-vip{
		margin-right: 30px;
		width: 165px;
		height: 50px;
		border: 1px solid #DEAA8A;
		border-radius: 25px;
		line-height: 50px;
		text-align: center;
		font-size: 28px;
		color: #DEAA8A;
	}

    .history{
        margin-top: 30px;
        width: 1140px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 10px;
    }

    .smallTitle{
        padding-top: 23px;
        margin: 0 0 0 20px;
        font-size: 18px;
        color: #144591;
    }

    .order{
        border-top: 1px #fff solid;
		border-bottom: 1px solid #DEDEDE;
		width: 1098px;
		height: 107px;
	}
	
	.order-desc{
		margin: 15px 0 0 20px;
	
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #666;
	}
	.order-desc img{
		height: 17px;
		width: 14px;
		margin-right: 10px;
	}
	.order-number{

		/* #ifdef MP-WEIXIN */
		display: flex;
		/* #endif */
		
		margin: 10px 0 0 44px;
		font-size: 14px;
		color: #666;
	}
	.order-status{
		display: flex;
		justify-content: space-between;
		margin: 10px 0 0 44px;
		font-size: 14px;
		color: #666;
		font-weight: bold;
	}
	
	.date{
		color: #B58F7D;
		font-size: 14px;
		margin-right: 20px;
	}
</style>